export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_POST = "FETCH_POST";
export const FETCH_BY_SEARCH = "FETCH_BY_SEARCH";
export const SIGNOUT = "SIGNOUT";
export const SIGNUP = "SIGNUP";
export const SIGNIN = "SIGNIN";
export const EDITPROFILE = "EDITPROFILE";
export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";
export const LIKE_POST = "LIKE_POST";
export const LIKE_COMMENT = "LIKE_COMMENT";
export const LIKE_REPLY = "LIKE_REPLY";
export const REFRESH_USER= "REFRESH_USER";
export const GET_OTHER_USER= "GET_OTHER_USER";